import React, {useContext, useEffect, useState, useRef} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import Form from 'form';
import dispatchRerender from 'dispatchRerender';
import FormContext from "formContext";
import PrivacyPolicyModal from "@Components/PrivacyPolicyModal";
import axios from 'axios';
import { useDataLayer} from "dataLayerHelper";
import SubMenu from "@Components/SubMenu";
import useRegisterSubmitHandler from 'registerSubmitHandler';
import NoticeInPage from "@Components/NoticeInPage";

function Register({intl, match, staticContext, location}) {
  const urlBrightfitParam = new URLSearchParams(location.search).get(process.env.REACT_APP_BRIGHTFIT_UTM_PARAMETER);
  if (process.env.REACT_APP_BRIGHTFIT === "true" && process.env.REACT_APP_COUNTRY === "au" && urlBrightfitParam === 'brightfit' && typeof window !== "undefined") {
    localStorage.setItem('redirectToBrightfit', 'true');
  }

  const headerTitleLeft = intl.formatMessage({id: "Register.Header.Title.Left"});
  const headerTitleRight = intl.formatMessage({id: "Register.Header.Title.Right"});
  const {setAdditionalData, formData, setFormData, currentStep, setErrors} = useContext(FormContext);
  const [modalOpen, setModalOpen] = useState(false);
  const {handleRegisterSubmit} = useRegisterSubmitHandler();

  const { locale } = intl;

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      Promise.all([
        axios.get(`${process.env.REACT_APP_API_PREFIX}/job-categories-list`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/branch-specialisms`),
        process.env.REACT_APP_HAS_COUNTRY_LIST === "true"  ? axios.get(`${process.env.REACT_APP_API_PREFIX}/country-list`) : '',
      ])
        .then(([jobCategoriesListResponse, branchSpecialismsResponse, countryListResponse]) => {
          setAdditionalData({
            regions: jobCategoriesListResponse?.data?.regions,
            specialisms: jobCategoriesListResponse?.data?.specialisms,
            masterLists: jobCategoriesListResponse?.data?.masterLists,
            specialismOptions: jobCategoriesListResponse?.data?.specialismOptions,
            jobTypes: jobCategoriesListResponse?.data?.jobTypeList,
            branches: branchSpecialismsResponse?.data,
            businessSectors: jobCategoriesListResponse?.data?.businessSectors,
            salaries: jobCategoriesListResponse?.data?.salaries,
            countries: process.env.REACT_APP_COUNTRY === "it" ? jobCategoriesListResponse?.data?.countries : countryListResponse?.data?.countries
          }
        );
        });
    }

    dispatchRerender();
    setFormData([]);
  }, []);

  const handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === 'checkbox') {
      let checkboxValue = event.target.value;
      if (typeof checkboxValue === 'string' && checkboxValue === 'true') {
        checkboxValue = true;
      }
      value = event.target.checked ? checkboxValue : null;
    }

    // Handle privacy checkbox.
    if ((name === 'accept_privacy_policy' || name === 'accept_terms_conditions_and_privacy_policy') && process.env.REACT_APP_DISPLAY_MODAL_ON_REGISTER_PRIVACY_POLICY === 'true') {
      if (value) {
        setModalOpen(true);
        return;
      }
    }

    setFormData((prev) => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }
      prev[currentStep][name] = value;

      return {
        ...prev
      }
    });

    setErrors((prev) => {
      if (prev && prev.hasOwnProperty(name) && value) {
        delete prev[name];
      }

      return {
        ...prev,
      };
    });
  };

  const onAcceptConsent = () => {
    setFormData((prev) => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }
      prev[currentStep]['accept_privacy_policy'] = 'yes';
      prev[currentStep]['accept_terms_conditions_and_privacy_policy'] = 'yes';
      return {
        ...prev
      }
    });
    setModalOpen(false);
  };

  const modalFooter = () => (
    <>
      <button onClick={onAcceptConsent} className="button button--m button--filled button--full-width">
        {intl.formatMessage({id: 'Register.Section.PersonalDataProcessing.Checkbox.PrivacyPolicy.Modal.Button'})}
      </button>
    </>
  );

  const handleSubmit = event => {
    event.preventDefault();
    handleRegisterSubmit();
  };

  const onCloseModal = () => {
    setModalOpen(false)
  };

  const renderModal = () => {
    if (!modalOpen) {
      return '';
    }
    return (
      <PrivacyPolicyModal
        title={intl.formatMessage({id: 'Register.Section.PersonalDataProcessing.Checkbox.PrivacyPolicy.Modal.Title'})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={false}
      />
    );
  };

  const noticeSettings = {
    type: 'warning',
    message: intl.formatMessage({id: "Cutover.Register"})
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.Register'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header header--text bg-variant-brand-primary"/>
      </Helmet>
      <div className="header header--text bg-variant-brand-primary">
        <div className="header__wrapper wrapper">
          <SubMenu />
          <div className="header__content header__content--l content-block header__content--full-width has-breadcrumbs">
            <h1 className="content-block__title">
              <span className="content-block__title-top">{headerTitleLeft}</span>
              <span className="content-block__title-bottom text--emphasis">{headerTitleRight}</span>
            </h1>
          </div>
        </div>
      </div>
      { process.env.REACT_APP_COUNTRY === "it" &&
        <div className="block block--xs">
          <div className="wrapper block__wrapper">
            <NoticeInPage settings={noticeSettings} style={{maxWidth: '100%'}} />
          </div>
        </div>
      }
      <Form name='register' handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit} hideGeneralErrors={true} />
      {process.env.REACT_APP_DISPLAY_MODAL_ON_REGISTER_PRIVACY_POLICY === 'true' && renderModal()}
    </>
  )
}

export default injectIntl(Register);
